.owl-carousel .animated {
  animation-duration: 1s;
  animation-fill-mode: both;
}

.owl-carousel .owl-animated-in {
  z-index: 0;
}

.owl-carousel .owl-animated-out {
  z-index: 1;
}

.owl-carousel .fadeOut {
  animation-name: fadeOut;
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

.owl-height {
  -o-transition: height .5s ease-in-out;
  transition: height .5s ease-in-out;
}

.owl-carousel {
  -webkit-tap-highlight-color: transparent;
  z-index: 1;
  width: 100%;
  display: none;
  position: relative;
}

.owl-carousel .owl-stage {
  -ms-touch-action: pan-Y;
  position: relative;
}

.owl-carousel .owl-stage:after {
  content: ".";
  clear: both;
  visibility: hidden;
  height: 0;
  line-height: 0;
  display: block;
}

.owl-carousel .owl-stage-outer {
  position: relative;
  overflow: hidden;
  -webkit-transform: translate3d(0, 0, 0);
}

.owl-carousel .owl-controls .owl-dot, .owl-carousel .owl-controls .owl-nav .owl-next, .owl-carousel .owl-controls .owl-nav .owl-prev {
  cursor: pointer;
  cursor: hand;
  -webkit-user-select: none;
  user-select: none;
  -khtml-user-select: none;
}

.owl-carousel.owl-loaded {
  display: block;
}

.owl-carousel.owl-loading {
  opacity: 0;
  display: block;
}

.owl-carousel.owl-hidden {
  opacity: 0;
}

.owl-carousel .owl-refresh .owl-item {
  display: none;
}

.owl-carousel .owl-item {
  float: left;
  -webkit-backface-visibility: hidden;
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  user-select: none;
  min-height: 1px;
  position: relative;
}

.owl-carousel .owl-item img {
  -webkit-transform-style: preserve-3d;
  width: 100%;
  display: block;
}

.owl-carousel.owl-text-select-on .owl-item {
  -webkit-user-select: auto;
  user-select: auto;
}

.owl-carousel .owl-grab {
  cursor: move;
  cursor: -webkit-grab;
  cursor: -o-grab;
  cursor: -ms-grab;
  cursor: grab;
}

.owl-carousel.owl-rtl {
  direction: rtl;
}

.owl-carousel.owl-rtl .owl-item {
  float: right;
}

.no-js .owl-carousel {
  display: block;
}

.owl-carousel .owl-item .owl-lazy {
  opacity: 0;
  -o-transition: opacity .4s ease;
  transition: opacity .4s;
}

.owl-carousel .owl-item img {
  transform-style: preserve-3d;
}

.owl-carousel .owl-video-wrapper {
  background: #000;
  height: 100%;
  position: relative;
}

.owl-carousel .owl-video-play-icon {
  cursor: pointer;
  z-index: 1;
  -webkit-backface-visibility: hidden;
  -o-transition: scale .1s ease;
  background: url("video-bg.1074155f.jpg") no-repeat;
  width: 80px;
  height: 80px;
  margin-top: -40px;
  margin-left: -40px;
  transition: scale .1s;
  position: absolute;
  top: 50%;
  left: 50%;
}

.owl-carousel .owl-video-play-icon:hover {
  -webkit-transition: scale(1.3, 1.3);
  -moz-transition: scale(1.3, 1.3);
  -ms-transition: scale(1.3, 1.3);
  -o-transition: scale(1.3, 1.3);
  transition: scale(1.3, 1.3);
}

.owl-carousel .owl-video-playing .owl-video-play-icon, .owl-carousel .owl-video-playing .owl-video-tn {
  display: none;
}

.owl-carousel .owl-video-tn {
  opacity: 0;
  -webkit-background-size: contain;
  -moz-background-size: contain;
  -o-background-size: contain;
  -o-transition: opacity .4s ease;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  height: 100%;
  transition: opacity .4s;
}

.owl-carousel .owl-video-frame {
  z-index: 1;
  position: relative;
}
/*# sourceMappingURL=index.5b399b30.css.map */
